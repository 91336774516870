@tailwind base;
@tailwind components;
@tailwind utilities;

/* We'll conditionally use fonts based on language selection */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #050508;
}

html {
  /* Allowing scrolling - removed overflow:hidden */
}

/* Language-specific styles */
html.lang-ja body {
  /* Use Hiragino Sans for Japanese, with fallbacks */
  font-family: 'Hiragino Sans', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'MS Pゴシック', sans-serif;
  /* Increase line height for better readability with Japanese characters */
  line-height: 1.8;
}

/* Adjust spacing and line height for Japanese text in specific components */
html.lang-ja .hero-title,
html.lang-ja h1, 
html.lang-ja h2, 
html.lang-ja h3, 
html.lang-ja h4 {
  font-family: 'Hiragino Sans', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'MS Pゴシック', sans-serif;
  letter-spacing: -0.02em;
  line-height: 1.5;
}

html.lang-ja .text-sm {
  /* Better size for Japanese small text */
  font-size: 0.875rem;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Notion-like Markdown styling - optimized for slide visibility */
.markdown-content,
.custom-markdown-content {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #e2e8f0;
  line-height: 1.6;
  font-size: 0.95rem;
  width: 100%;
  overflow-y: auto;
}

/* Language-specific markdown styles */
html.lang-ja .markdown-content,
html.lang-ja .custom-markdown-content {
  font-family: 'Hiragino Sans', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'MS Pゴシック', sans-serif;
  line-height: 1.8;
  font-size: 1rem;
}

/* Headings - more compact */
.markdown-content h1, 
.markdown-content h2, 
.markdown-content h3, 
.markdown-content h4,
.custom-markdown-content h1, 
.custom-markdown-content h2, 
.custom-markdown-content h3, 
.custom-markdown-content h4 {
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.markdown-content h1,
.custom-markdown-content h1 {
  font-size: 1.75rem;
  color: #f8fafc;
  border-bottom: 1px solid #334155;
  padding-bottom: 0.25rem;
}

.markdown-content h2,
.custom-markdown-content h2 {
  font-size: 1.35rem;
  color: #f1f5f9;
}

.markdown-content h3,
.custom-markdown-content h3 {
  font-size: 1.15rem;
  color: #e2e8f0;
}

/* Basic text elements - reduced spacing */
.markdown-content p,
.custom-markdown-content p {
  margin-bottom: 0.75rem;
}

/* Lists - more compact */
.markdown-content ul,
.custom-markdown-content ul {
  list-style-type: disc;
  padding-left: 1.25rem;
  margin-bottom: 0.75rem;
}

.markdown-content ol,
.custom-markdown-content ol {
  list-style-type: decimal;
  padding-left: 1.25rem;
  margin-bottom: 0.75rem;
}

.markdown-content li,
.custom-markdown-content li {
  margin-bottom: 0.25rem;
}

/* Enhanced list styling */
.markdown-content ul li::marker,
.custom-markdown-content ul li::marker {
  color: #60a5fa;
}

.markdown-content ol li::marker,
.custom-markdown-content ol li::marker {
  color: #60a5fa;
  font-weight: bold;
}

/* Code blocks - more compact */
.markdown-content pre,
.custom-markdown-content pre {
  background-color: #1e293b;
  border-radius: 0.375rem;
  padding: 0.75rem;
  overflow-x: auto;
  margin-bottom: 0.75rem;
  font-size: 0.85rem;
}

.markdown-content code,
.custom-markdown-content code {
  font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
  font-size: 0.85rem;
}

/* Notion-like code sandbox */
.code-sandbox {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  margin-bottom: 0.75rem;
}

.code-sandbox:hover {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

/* Links */
.markdown-content a,
.custom-markdown-content a {
  color: #60a5fa;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-color 0.2s ease;
}

.markdown-content a:hover,
.custom-markdown-content a:hover {
  border-bottom-color: #60a5fa;
}

/* Blockquotes - more compact */
.markdown-content blockquote,
.custom-markdown-content blockquote {
  border-left: 3px solid #4b5563;
  padding-left: 0.75rem;
  margin: 0.75rem 0;
  color: #9ca3af;
  font-size: 0.9rem;
}

/* Callout boxes - more compact */
.markdown-content blockquote.info,
.custom-markdown-content blockquote.info {
  background-color: rgba(59, 130, 246, 0.1);
  border-left-color: #3b82f6;
}

.markdown-content blockquote.warning,
.custom-markdown-content blockquote.warning {
  background-color: rgba(245, 158, 11, 0.1);
  border-left-color: #f59e0b;
}

.markdown-content blockquote.error,
.custom-markdown-content blockquote.error {
  background-color: rgba(239, 68, 68, 0.1);
  border-left-color: #ef4444;
}

.markdown-content blockquote.success,
.custom-markdown-content blockquote.success {
  background-color: rgba(34, 197, 94, 0.1);
  border-left-color: #22c55e;
}

/* Tables - more compact */
.markdown-content table,
.custom-markdown-content table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0.75rem;
  font-size: 0.9rem;
}

.markdown-content th,
.markdown-content td,
.custom-markdown-content th,
.custom-markdown-content td {
  border: 1px solid #374151;
  padding: 0.35rem;
}

.markdown-content th,
.custom-markdown-content th {
  background-color: #1e293b;
  font-weight: 600;
}

.markdown-content tr:nth-child(even),
.custom-markdown-content tr:nth-child(even) {
  background-color: rgba(30, 41, 59, 0.4);
}

/* Horizontal rule */
.markdown-content hr,
.custom-markdown-content hr {
  border: 0;
  height: 1px;
  background-color: #374151;
  margin: 1.5rem 0;
}

/* Highlights and colored text */
.markdown-content mark,
.custom-markdown-content mark {
  background-color: rgba(250, 204, 21, 0.2);
  color: #fef08a;
  padding: 0 0.25rem;
  border-radius: 0.25rem;
}

/* Emoji styling */
.emoji {
  font-size: 1.1em;
  margin: 0 0.1em;
  vertical-align: -0.1em;
}

/* Max height for code blocks to prevent overflow */
.markdown-content pre,
.custom-markdown-content pre {
  max-height: 200px;
  overflow-y: auto;
}

/* Ensure content fits in slides */
.markdown-content,
.custom-markdown-content {
  width: 100%;
  line-height: 1.6;
  overflow-y: auto;
}

/* Add selectable-text class for AskAI text selection */
.selectable-text {
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}

/* Make ReactMarkdown content selectable */
.markdown-content *,
.custom-markdown-content * {
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
}

/* Preserve selection when interacting with AskAI button */
.preserve-selection {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  pointer-events: auto;
}

/* Monaco editor selection styling for AskAI */
.monaco-editor .selected-text {
  background-color: rgba(97, 175, 239, 0.3) !important;
}

.monaco-editor .monaco-editor-background {
  user-select: text !important;
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
}

/* Override Monaco's default selection behavior */
.monaco-editor .view-overlays .selected-text {
  background-color: rgba(97, 175, 239, 0.3) !important;
}

/* Ensure Monaco editor doesn't interfere with text selection */
.monaco-editor, 
.monaco-editor .overflow-guard, 
.monaco-editor .monaco-scrollable-element {
  pointer-events: auto !important;
}

/* Notion-like code snippet styling */
.code-snippet {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  margin-bottom: 1rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

.code-snippet pre {
  margin: 0;
  padding: 0;
  max-height: 400px;
  overflow-y: auto;
  line-height: 1.5;
}

.code-snippet code {
  font-family: inherit;
  tab-size: 2;
}

/* Syntax highlighting classes for different languages */
.code-snippet .keyword {
  color: #ff79c6;
}

.code-snippet .function {
  color: #50fa7b;
}

.code-snippet .string {
  color: #f1fa8c;
}

.code-snippet .number {
  color: #bd93f9;
}

.code-snippet .comment {
  color: #6272a4;
}

/* Scrollbar styling for code snippets */
.code-snippet pre::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.code-snippet pre::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.code-snippet pre::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.code-snippet pre::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Add utility classes for language-specific fonts */
.font-ja {
  font-family: 'Hiragino Sans', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'MS Pゴシック', sans-serif !important;
}

.font-en {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

/* Custom code editor styling for textarea */
.code-editor-display {
  font-family: monospace;
  white-space: pre;
  line-height: 1.5;
  tab-size: 2;
}

.code-editor-display .code-snippet {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  background: transparent !important;
}

.code-editor-display .code-snippet pre {
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  max-height: none !important;
  overflow: visible !important;
}

.code-editor-display code {
  background: transparent !important;
  color: inherit !important;
}

/* Hide code block header from the overlay */
.code-editor-display .code-snippet > div:first-child {
  display: none;
}

/* Hide scrollbars but keep functionality */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}

/* Ensure the text color is transparent but keep the caret visible */
textarea.text-transparent {
  color: transparent !important;
  caret-color: white !important;
}

/* JSON Editor styling */
.json-editor-textarea {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  line-height: 1.5;
  tab-size: 2;
  background-color: #1e1e1e;
  color: #d4d4d4;
}
