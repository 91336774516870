.App {
  height: 100vh;
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Smooth scrolling for the entire document - DISABLED to only use smooth scrolling for Continue button
html {
  scroll-behavior: smooth;
}
*/

/* Styles for the vertical scrolling slides - keeping only essential rules */
.slide-container {
  overflow-y: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.slide {
  scroll-snap-align: start;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden !important;
}

/* Slide transition animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Make slide content fit within viewport without scrolling */
.slide-content {
  animation: slideIn 0.5s ease-out;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 56px); /* Subtract header height */
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden !important;
  padding: 2rem 0; /* Add vertical padding for better spacing */
}

/* Remove scrollable content area since we've split slides */
.slide-content-scrollable {
  flex: 1;
  overflow-y: hidden !important; /* Prevent scrolling within content */
  padding-right: 10px;
  margin-bottom: 80px; /* Increased bottom margin for Ask AI button */
}

/* Apply smooth scrolling only when navigating with Continue button */
.smooth-scroll {
  scroll-behavior: smooth !important;
}

/* Page transitions */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes slideInRight {
  from { 
    transform: translateX(30px);
    opacity: 0;
  }
  to { 
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from { 
    transform: translateX(-30px);
    opacity: 0;
  }
  to { 
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutRight {
  from { 
    transform: translateX(0);
    opacity: 1;
  }
  to { 
    transform: translateX(30px);
    opacity: 0;
  }
}

@keyframes slideOutLeft {
  from { 
    transform: translateX(0);
    opacity: 1;
  }
  to { 
    transform: translateX(-30px);
    opacity: 0;
  }
}

/* Animation classes */
.page-transition-enter {
  animation: fadeIn 0.3s ease forwards, slideInRight 0.3s ease forwards;
}

.page-transition-exit {
  animation: fadeOut 0.3s ease forwards, slideOutLeft 0.3s ease forwards;
}

.page-transition-back-enter {
  animation: fadeIn 0.3s ease forwards, slideInLeft 0.3s ease forwards;
}

.page-transition-back-exit {
  animation: fadeOut 0.3s ease forwards, slideOutRight 0.3s ease forwards;
}

/* Mobile safe area handling - updated for Ask AI button only, no bottom nav */
.safe-bottom {
  padding-bottom: calc(env(safe-area-inset-bottom, 16px) + 60px); /* Reduced space without bottom nav */
}

@supports (padding: max(0px)) {
  .safe-bottom {
    padding-bottom: max(60px, calc(env(safe-area-inset-bottom, 16px) + 60px));
  }
}

/* Add padding to the bottom of the page content to make room for the Ask AI button only */
#root {
  padding-bottom: 60px;
}
